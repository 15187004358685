@tailwind base;
@tailwind components;
@tailwind utilities;


.fa-icon {
  display: inline-block;
  width: 1em;  /* Taille souhaitée de l'icône */
  height: 1em; /* Taille souhaitée de l'icône */
  overflow: hidden; /* Empêche tout débordement du contenu SVG */
}

.fa-icon svg {
  width: 100%;
  height: 100%;
}


body{
  font-family: 'OpenSans Regular', serif;
  background-color:black;
}

.extern-html p{
  margin-top:1em;
  margin-bottom:2em;
}

.extern-html b{
  color:yellow;
}

.extern-html h1{
  display: block;
  font-size: 2em;
  margin: 2.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.extern-html h2{
  display: block;
  font-size: 1.5em;
  margin: 2em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.extern-html h3{
  display: block;
  font-size: 1.17em;
  margin: 1.75em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.extern-html h4{
  display: block;
  font-size: 1em;
  margin: 1.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.extern-html h5{
  display: block;
  font-size: .83em;
  margin: 1em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.extern-html h6{
  display: block;
  font-size: .67em;
  margin: 1em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.extern-html ul, .extern-html ul{
  margin-top:1em;
  margin-bottom:1em;
}
.extern-html ul li, .extern-html ol li{
  margin-left:1em;
  margin-top:0.3em;
}
.extern-html ul li{
  list-style-type: circle;
}
.extern-html ol li{
  list-style-type: decimal;
}

.extern-html .iframe__container{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  max-width:1280px;
  max-height:720px;
  margin: 1em auto;
  box-shadow: 0 0 5px 3px rgba(255,255,255,0.3);
}

.extern-html .iframe__container iframe,
.extern-html .iframe__content{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}



@font-face {
  font-family: 'OpenSans Italic';
  src: url('../public/font/open-sans/OpenSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans Regular';
  src: url('../public/font/open-sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



/* Website's color palette */

/* Same as Tailwind's orange-600 */
.bg-color-1, .bg-color-1-hover:hover{
  background-color: rgb(221, 107, 32)
}

.border-grad-1, .border-grad-1-hover:hover{
  border-image:linear-gradient(135deg, #00d0ff, #00ffa0) 1;
}

.border-grad-2, .border-grad-2-hover:hover{
  border-image:linear-gradient(135deg, #8000ff, #00ffff) 1;
}

.border-grad-1-half, .border-grad-1-half-hover:hover{
  border-image:linear-gradient(135deg, #00d0ff80, #00ffa080) 1;
}

.border-grad-2-half, .border-grad-2-half-hover:hover{
  border-image:linear-gradient(135deg, #8000ff80, #00ffff80) 1;
}

/* Custom CSS properties, based on Tailwind syntax */

.bg-neutral-925, .bg-neutral-925-hover:hover{
  background-color: hsl(0,0%,7.5%);
}

.bg-neutral-950, .bg-neutral-950-hover:hover{
  background-color: hsl(0,0%,5%);
}

.bg-neutral-975, .bg-neutral-975-hover:hover{
  background-color: hsl(0,0%,2.5%);
}

.button-bg-animation{
  background: linear-gradient(110deg, rgb(221, 107, 32) 45%, rgb(221, 107, 32, 0) 55%);
  background-size: 300% 300%;
  animation: buttonBackgroundReverse 0.5s forwards;
}

.button-bg-animation:hover{
  animation: buttonBackground .5s;
}

.anim-duration--fast{
  animation-duration: .5s;
}
.anim-duration--default{
  animation-duration: 1s;
}

.anim-to-top{
  animation: transitionToTop 1s;
  animation-fill-mode: forwards;
}

.anim-to-top-fade-in{
  animation-name: fadeIn, transitionToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.anim-to-left-fade-in{
  animation-name: fadeIn, transitionToLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.anim-to-left-fade-out{
  animation-name: fadeIn, transitionToLeft;
  animation-duration: 1s;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

.anim-bg-size-zoom:hover{
  animation: backgroundSizeZoom forwards;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.anim-bg-size-zoom{
  animation: backgroundSizeUnzoom forwards;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}


@keyframes transitionToTopSmall{
  0%{
    transform: translateY(4em);
  }
  100%{
    transform: translateY(0%);
  }
}

@keyframes textDisplay0ToFull{
  0%{
    font-size:0
  }
  50%{
     font-size:0
   }
  100%{
    font-size:inherit
  }
}


@keyframes textDisplayFullTo0{
  0%{
    font-size:inherit
  }
  50%{
    font-size:0
  }
  100%{
    font-size:0
  }
}

@keyframes fadeIn{
  0%{
    opacity:0
  }
  100%{
    opacity:1
  }
}

@keyframes fadeOut{
  0%{
    opacity:1
  }
  100%{
    opacity:0
  }
}

@keyframes transitionToTop{
  0%{
    transform: translateY(80%);
  }
  100%{
    transform: translateY(0%);
  }
}

@keyframes transitionToLeft{
  0%{
    transform: translateX(40%);
  }
  100%{
    transform: translateX(0%);
  }
}

@keyframes buttonBackground{
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes buttonBackgroundReverse{
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes backgroundSizeZoom{
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 110%;
  }
}
@keyframes backgroundSizeUnzoom{
  0% {
    background-size: 110%;
  }
  100% {
    background-size: 100%;
  }
}


@keyframes textOpacityTo1{
  0% {
    opacity:0
  }
  100% {
    opacity:1
  }
}

@keyframes textOpacityTo0{
  0% {
    opacity:1
  }
  100% {
    opacity:0
  }
}


.hover-darken:hover{
  opacity:0.7
}

.reader::-webkit-scrollbar {
  width: 0.3em;
}

.reader::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.reader::-webkit-scrollbar-thumb {
  background-color: orange;
}

.mouse-shadow{

}